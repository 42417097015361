import type { O365GenericInputCreateRequest, O365InputCreateRequest } from 'o365/types';
import type { FormData } from 'o365/context/FormData';

export const toO365InputCreateRequest = ({
  clientId,
  clientSecret,
  contents,
  o365Name,
  o365DropSensitiveDlpLogs,
  o365StoreFullMessage,
  o365ThrottleEnabled,
  pollingInterval,
  subscriptionType,
  tenantId,
}: FormData): O365InputCreateRequest => ({
  name: o365Name.value,
  content_types: contents.value.split(','),
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  o365_tenant_id: tenantId.value,
  enable_throttling: !!o365ThrottleEnabled?.value,
  store_full_message: !!o365StoreFullMessage?.value,
  drop_sensitive_info_logs: !!o365DropSensitiveDlpLogs?.value,
  o365_client_id: clientId.value,
  o365_client_secret: clientSecret.value,
  endpoint_type: subscriptionType.value,
});

export const toGenericInputCreateRequest = ({
  clientId,
  clientSecret,
  contents,
  o365Name,
  o365StoreFullMessage,
  o365DropSensitiveDlpLogs,
  o365ThrottleEnabled,
  pollingInterval,
  subscriptionType,
  tenantId,
}: FormData): O365GenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.office365.Office365Input',
  title: o365Name.value,
  global: false,
  configuration: {
    content_types: contents.value.split(','),
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    tenant_id: tenantId.value,
    throttling_allowed: !!o365ThrottleEnabled?.value,
    drop_sensitive_dlp_logs: !!o365DropSensitiveDlpLogs?.value,
    store_full_message: !!o365StoreFullMessage?.value,
    client_id: clientId.value,
    client_secret: clientSecret.value,
    subscription_type: subscriptionType.value,
  },
});
