import PropTypes from 'prop-types';
import { useEffect } from 'react';

import history from 'util/History';

import workspaceRoutes from './common/Routes.js';

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state
const WorkspaceInputConfiguration = ({ url }) => {
  useEffect(() => {
    history.push(url);
  }, []);

  return null;
};

WorkspaceInputConfiguration.propTypes = {
  url: PropTypes.string,
};
WorkspaceInputConfiguration.defaultProps = {
  url: workspaceRoutes.INTEGRATIONS.Workspace.ACTIVITYAPI.index,
};

export default WorkspaceInputConfiguration;
