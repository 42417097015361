import React, { useContext } from 'react';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';

import { FormDataContext } from './context/FormData';
import { AdvancedOptionsContext } from './context/AdvancedOptions';
import AdditionalFields from './common/AdditionalFields';
import type {
  HandleFieldUpdateType,
  FormDataContextType,
  AdvancedOptionsContextType,
} from './types';

interface Props {
  onChange: HandleFieldUpdateType;
  azureBatchSizeError: boolean;
  azureWaitTimeError: boolean
}

const StyledAdditionalFields = styled(AdditionalFields)`
  margin: 0 0 35px;
`;

const FormAdvancedOptions: React.FC<Props> = ({ onChange, azureBatchSizeError, azureWaitTimeError }) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const { isAdvancedOptionsVisible, setAdvancedOptionsVisibility } = useContext<AdvancedOptionsContextType>(AdvancedOptionsContext);

  const {
    azureThrottleEnabled,
    azureBatchSize,
    azureWaitTime,
    azureStoreFullMessage,
  } = formData;

  const handleToggle: (visible: boolean) => void = (visible) => {
    setAdvancedOptionsVisibility(visible);
  };

  return (
    <StyledAdditionalFields title="Advanced Options"
                            visible={isAdvancedOptionsVisible}
                            onToggle={handleToggle}>

      <Input id="azureBatchSize"
             name="azureBatchSize"
             type="number"
             value={azureBatchSize.value || azureBatchSize.defaultValue || ''}
             min="10"
             max="1000"
             onChange={onChange}
             required
             help="The Maximum message count received from Event Hub at once."
             label="Maximum Batch Size" />
      {azureBatchSizeError && <span> <strong>The message count can be between 1 to 1000</strong></span>}
      <Input id="azureWaitTime"
             name="azureWaitTime"
             type="number"
             value={azureWaitTime.value || azureWaitTime.defaultValue || ''}
             min="1"
             max="30"
             onChange={onChange}
             required
             help="The maximum amount of time to wait for the Maximum Batch Size to be reached. The average wait time is 3 minutes"
             label="Maximum Wait Time" />
      {azureWaitTimeError && <span> <strong>The wait time can be between 1 to 30</strong></span>}
      <Input id="azureThrottleEnabled"
             type="checkbox"
             value="enable-throttling"
             defaultChecked={azureThrottleEnabled?.value}
             onChange={onChange}
             label="Enable Throttling"
             help="If enabled, no new messages will be read from this input until Graylog catches up with its message load. This is typically useful for inputs reading from files or message queue systems like AMQP or Kafka. If you regularly poll an external system, e.g. via HTTP, you normally want to leave this disabled." />
      <Input id="azureStoreFullMessage"
             type="checkbox"
             value="enable-storeFullMessage"
             defaultChecked={azureStoreFullMessage?.value}
             onChange={onChange}
             label="Store Full Message"
             help="Store the full original Azure log record message as full_message?" />
    </StyledAdditionalFields>
  );
};

export default FormAdvancedOptions;
