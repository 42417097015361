import { useEffect } from "react";
import history from "util/History";
import AzureRoutes from "./common/Routes";

type Props = { url: string };

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state

const AzureLogsInputConfiguration: React.FC<Props> = ({
  url = AzureRoutes.INTEGRATIONS.Azure.ACTIVITYAPI.index,
}) => {
  useEffect(() => {
    history.push(url);
  }, []);

  return null;
};

export default AzureLogsInputConfiguration;
