import React, { createContext, useState } from "react";
import { AdvancedOptionsContextType } from "../types";

export const AdvancedOptionsContext =
  createContext<AdvancedOptionsContextType>(null);

export const AdvancedOptionsProvider: React.FC = ({ children }) => {
  const [isAdvancedOptionsVisible, setAdvancedOptionsVisibility] =
    useState<boolean>(false);

  return (
    <AdvancedOptionsContext.Provider
      value={{
        isAdvancedOptionsVisible,
        setAdvancedOptionsVisibility,
      }}
    >
      {children}
    </AdvancedOptionsContext.Provider>
  );
};
