import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ValidatedInput from './common/ValidatedInput';
import FormWrap from './common/FormWrap';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';

const StepAuthorize = ({ onSubmit, onChange }) => {
  const { formData } = useContext(FormDataContext);
  const [formError, setFormError] = useState(null);

  const { secretPath } = formData;

  const isFormValid = formValidation.isFormValid([
    'gcpName',
    'projectId',
    'clientId',
    'secretPath',
  ], formData);

  return (
    <FormWrap onSubmit={onSubmit}
              buttonContent="Verify &amp; Proceed"
              disabled={isFormValid}
              title=""
              error={formError}
              description="">

      <ValidatedInput id="gcpName"
                      type="text"
                      fieldData={formData.gcpName}
                      onChange={onChange}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      defaultValue={secretPath?.value}
                      required />

      <ValidatedInput id="projectId"
                      type="text"
                      fieldData={formData.projectId}
                      onChange={onChange}
                      label="Project ID"
                      help="The GUID of the project to which the logs belong."
                      autoComplete="off"
                      required />

      <ValidatedInput id="clientId"
                      type="text"
                      onChange={onChange}
                      fieldData={formData.clientId}
                      help="The GUID of your application."
                      label="Application (client) ID"
                      required />

      <ValidatedInput id="secretPath"
                      type="text"
                      onChange={onChange}
                      fieldData={formData.secretPath}
                      label="Service account key path"
                      help="The path of the service account key json file."
                      required >
        </ValidatedInput>
                      
    </FormWrap>
  );
};

StepAuthorize.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StepAuthorize;
