import React, { createContext, useState } from "react";
import { SidebarContextType } from "../types";

export const SidebarContext = createContext<SidebarContextType | null>(null);

export const SidebarProvider: React.FC = ({ children }) => {
  const [sidebar, setSidebar] = useState<React.ReactElement>(<></>);
  const clearSidebar = () => {
    setSidebar(<></>);
  };

  return (
    <SidebarContext.Provider value={{ sidebar, clearSidebar, setSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
