const DEFAULT_SETTINGS = {
  /*
  fieldId: { // Same ID as supplied to <Input />
    value: '',
    defaultValue: '', // Update StepReview.jsx & relevant step(s) if you need to output
  }
  */

  /* Default Advanced Settings */
  batchSize: {
    defaultValue: '10000',
  },
  throttleEnabled: {
    value: false, // We want to default to true on render, but never compare the default
  },
  subscribeToAllLog: {
    value: true, // We want to default to true on render, but never compare the default
  },

  gcpName: {
    value: '',
  },

  availableLogs: {
  value: 'VPC_FLOW_LOGS, FIREWALL_LOGS, AUDIT_LOGS_DATA_ACCESS, AUDIT_LOGS_ACTIVITY, AUDIT_LOGS_SYSTEM_EVENT, AUDIT_LOGS_POLICY_DENIED',
},

  logs: {
    value: '',
  },

  gcpThrottleEnabled: {
    value: undefined,
  },


  pollingInterval: {
    value: 5,
  },

};

export default DEFAULT_SETTINGS;
