import React from "react";
import PageHeader from "components/common/PageHeader";

import EmbeddedAzureApp from "./EmbeddedAzureApp";
import { ExternalLink } from "components/common";

const AzureApp: React.FC = () => {
  return (
    <>
      <PageHeader title="Azure Event Hub Integrations">
        <span>This feature retrieves log records from Azure Platform.</span>
        <p>
          You need to have{" "}
          <ExternalLink href="https://portal.azure.com/m">
            Azure Event Hub
          </ExternalLink>
          .{" "}
        </p>
      </PageHeader>
      <EmbeddedAzureApp />
    </>
  );
};

export default AzureApp;
