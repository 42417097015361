import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import ValidatedInput from "./common/ValidatedInput";
import FormWrap from "./common/FormWrap";
import useFetch from "./common/hooks/useFetch";
import { ApiRoutes } from "./common/Routes";
import { FormDataContext } from "./context/FormData";
import { renderOptions } from "./common/Options";
import formValidation from "./utils/formValidation";
import fetch from "logic/rest/FetchProvider";
import { qualifyUrl } from 'util/URLUtils';

const StepAuthorize = ({ onSubmit, onChange }) => {
  const { formData } = useContext(FormDataContext);
  const [formError, setFormError] = useState(null);

  const { secretPath } = formData;

  const handleSubmit = () => {
    let fetcher = Promise.resolve();
    fetcher = fetch("POST", qualifyUrl(ApiRoutes.INTEGRATIONS.Gmail.CHECK_CREDENTIALS), {
      gmail_project_id: formData?.projectId?.value || "",
      gmail_client_id: formData?.clientId?.value || "",
      gmail_secret_path: formData?.secretPath?.value || "",
    });
    fetcher
      .then((result) => {
      console.log("result", result)
        if (result.result === "valid") {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message:
              "Unable to connect to Gmail using provided configuration.",
          });
        }
      })
      .catch((err) => {
        console.log("error", err)
        setFormError({
          full_message: err.message,
          nice_message:
            "Unable to connect to Gmail using provided configuration.",
        });
      });
    // .finally(() => {});
  };

  const isFormValid = formValidation.isFormValid(
    ["gmailName", "projectId", "clientId", "secretPath"],
    formData
  );

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Verify &amp; Proceed"
      disabled={isFormValid}
      title=""
      error={formError}
      description=""
    >
      <ValidatedInput
        id="gmailName"
        type="text"
        fieldData={formData.gcpName}
        onChange={onChange}
        placeholder="Input Name"
        label="Input Name"
        autoComplete="off"
        help="Select a name of your new input that describes it."
        defaultValue={secretPath?.value}
        required
      />
      <ValidatedInput
        id="projectId"
        type="text"
        fieldData={formData.projectId}
        onChange={onChange}
        label="Project ID"
        help="The GUID of the project to which the logs belong."
        autoComplete="off"
        required
      />
      <ValidatedInput
        id="clientId"
        type="text"
        onChange={onChange}
        fieldData={formData.clientId}
        help="The GUID of your application."
        label="Application (client) ID"
        required
      />
      <ValidatedInput
        id="secretPath"
        type="text"
        onChange={onChange}
        fieldData={formData.secretPath}
        label="Service account key path"
        help="The path of the service account key json file."
        required
      ></ValidatedInput>
    </FormWrap>
  );
};

StepAuthorize.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StepAuthorize;