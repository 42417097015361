import React from "react";

import { SidebarProvider } from "./context/Sidebar";
import { FormDataProvider } from "./context/FormData";
import { StepsProvider } from "./context/Steps";
import { AdvancedOptionsProvider } from "./context/AdvancedOptions";
import Azure from "./Azure";
import INITIAL_FORMDATA from "./_initialFormData";
import { toGenericInputCreateRequest } from "./common/formDataAdapter";
import {
  AzureGenericInputCreateRequest,
  FormDataType,
  HandleSubmitType,
} from "./types";

const EmbeddedAzureApp: React.FC = () => {

  return (
    <StepsProvider>
      <FormDataProvider initialFormData={INITIAL_FORMDATA}>
        <SidebarProvider>
          <AdvancedOptionsProvider>
            <Azure
              externalInputSubmit={false}
            />
          </AdvancedOptionsProvider>
        </SidebarProvider>
      </FormDataProvider>
    </StepsProvider>
  );
};

export default EmbeddedAzureApp;
