import React from 'react';
import { PropTypes } from 'prop-types';

import PageHeader from 'components/common/PageHeader';

import EmbeddedGCPApp from './EmbeddedGCPApp';

const ExternalLink = ({ href, title }) => <a href={href} rel="noopener noreferrer" target="_blank">{title}</a>;

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const GCPApp = () => {
  return (
    <>
      <PageHeader title="Google Cloud Integrations">
        <span>This feature retrieves log records from Google Cloud Platform.</span>
        <p>
          You need to have <ExternalLink href="https://console.cloud.google.com" title="Google Cloud" />,{' '}
        </p>
      </PageHeader>
      <EmbeddedGCPApp />
    </>
  );
};

GCPApp.propTypes = {};

export default GCPApp;
