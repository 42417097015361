import type { GmailGenericInputCreateRequest, GmailInputCreateRequest } from 'gmail/types';
import type { FormData } from 'gmail/context/FormData';

export const toGmailInputCreateRequest = ({
  projectId,
  secretPath,
  gmailName,
  gmailThrottleEnabled,
  pollingInterval,
  clientId,
 }: FormData): GmailInputCreateRequest => ({
  name: gmailName.value,
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  gmail_client_id: clientId.value,
  gmail_project_id: projectId.value,
  enable_throttling: !!gmailThrottleEnabled?.value,
  gmail_secret_path: secretPath.value,
});

export const toGenericInputCreateRequest = ({
  projectId,
  secretPath,
  gmailName,
  clientId,
  gmailThrottleEnabled,
  pollingInterval,
}: FormData): GmailGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.gmail.GmailInput',
  title: gmailName.value,
  global: false,
  configuration: {
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    client_id:clientId.value,
    project_id: projectId.value,
    throttling_allowed: !!gmailThrottleEnabled?.value,
    secret_path: secretPath.value,
  },
});
