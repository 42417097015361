import React, { useContext, useState } from "react";
import ValidatedInput from "./common/ValidatedInput";
import FormWrap from "./common/FormWrap";
import { ApiRoutes } from "./common/Routes";
import { FormDataContext } from "./context/FormData";
import formValidation from "./utils/formValidation";
import { qualifyUrl } from "util/URLUtils";
import fetch from "logic/rest/FetchProvider";
import {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
} from "./types";

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize: React.FC<StepAuthorizeProps> = ({
  onSubmit,
  onChange,
}) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { connectionString } = formData;

  const handleSubmit = () => {
    let fetcher = fetch(
      "POST",
      qualifyUrl(ApiRoutes.INTEGRATIONS.Azure.CHECK_CREDENTIALS),
      {
        azure_event_hub_name: formData?.eventHubName?.value || "",
        azure_connection_string: formData?.connectionString?.value || "",
        azure_consumer_group: formData?.consumerGroup?.value || "",
      }
    );
    fetcher
      .then((result: any) => {
        if (result.result === "valid") {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message:
              "Unable to connect to Azure using provided configuration.",
          });
        }
      })
      .catch((err) => {
        setFormError({
          full_message: err.message,
          nice_message:
            "Unable to connect to Azure using provided configuration.",
        });
      });
  };

  const isFormValid = formValidation.isFormValid(
    ["azureName", "eventHubName", "connectionString", "consumerGroup"],
    formData
  );

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Verify Connection &amp; Proceed"
      disabled={isFormValid}
      title=""
      error={formError}
      description=""
    >
      <ValidatedInput
        className=""
        id="azureName"
        type="text"
        fieldData={formData.azureName}
        onChange={onChange}
        placeholder="Input Name"
        label="Input Name"
        autoComplete="off"
        help="Select a name of your new input that describes it."
        defaultValue={connectionString?.value}
        required
      />

      <ValidatedInput
        className=""
        id="eventHubName"
        type="text"
        fieldData={formData.eventHubName}
        onChange={onChange}
        label="Event Hub Name"
        help="The Event Hub Name of the tenant."
        autoComplete="off"
        required
      />

      <ValidatedInput
        className=""
        id="connectionString"
        type="password"
        onChange={onChange}
        fieldData={formData.connectionString}
        help="The connection string required to communicate with Event Hub."
        label="Connection String"
        required
      />

      <ValidatedInput
        className=""
        id="consumerGroup"
        type="text"
        onChange={onChange}
        fieldData={formData.consumerGroup}
        label="Consumer Group"
        help="The consumer group name in the Event Hub."
        required
      ></ValidatedInput>
    </FormWrap>
  );
};

export default StepAuthorize;
